import axios from '@/common/axios'
import qs from 'qs'

export function addIllumination (data) {
  return axios({
    method: 'post',
    url: '/quality/illumination/add',
    data: qs.stringify(data)
  })
}

export function deleteIllumination (id) {
  return axios({
    method: 'delete',
    url: '/quality/illumination/delete/' + id
  })
}

export function updateIllumination (data) {
  return axios({
    method: 'put',
    url: '/quality/illumination/update',
    data: qs.stringify(data)
  })
}

export function selectIlluminationInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/illumination/info/' + id
  })
}

export function selectIlluminationList (query) {
  return axios({
    method: 'get',
    url: '/quality/illumination/list',
    params: query
  })
}
