<template>
  <div id="illumination">
    <el-dialog
      :title="illuminationFormTitle"
      width="1200px"
      :visible.sync="illuminationDialogVisible"
      :close-on-click-modal="false"
      @close="illuminationDialogClose"
    >
      <el-form
        ref="illuminationFormRef"
        :model="illuminationForm"
        :rules="illuminationFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="房间名称" prop="roomName">
              <el-input v-model="illuminationForm.roomName" placeholder="请输入房间名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="灯管数" prop="tubeQuantity">
              <el-input v-model="illuminationForm.tubeQuantity" placeholder="请输入灯管数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="1点(阴暗处)" prop="onePoints">
              <el-input v-model="illuminationForm.onePoints" placeholder="请输入1点(阴暗处)" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="2点(阴暗处)" prop="twoPoints">
              <el-input v-model="illuminationForm.twoPoints" placeholder="请输入2点(阴暗处)" clearable />
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="3点(灯管下)" prop="threePoints">
              <el-input v-model="illuminationForm.threePoints" placeholder="请输入3点(灯管下)" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="测试结果" prop="testResults">
              <el-input v-model="illuminationForm.testResults" placeholder="请输入测试结果" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="illuminationForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测人" prop="inspector">
              <el-input v-model="illuminationForm.inspector" placeholder="请输入检测人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测日期" prop="testDate">
              <el-date-picker v-model="illuminationForm.testDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="illuminationForm.reviewer" placeholder="请输入复核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="复核时间" prop="reviewTime">
              <el-date-picker v-model="illuminationForm.reviewTime" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="illuminationDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="illuminationFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.deviceNo" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="illuminationPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="roomName" label="房间名称" />
      <el-table-column prop="tubeQuantity" label="灯管数" />
      <el-table-column prop="onePoints" label="1点(阴暗处)" />
      <el-table-column prop="twoPoints" label="2点(阴暗处)" />
      <el-table-column prop="threePoints" label="3点(灯管下)" />
      <el-table-column prop="testResults" label="测试结果" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="inspector" label="检测人" />
      <el-table-column prop="testDate" label="检测日期" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column prop="reviewTime" label="复核时间" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="illuminationPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addIllumination, deleteIllumination, updateIllumination, selectIlluminationInfo, selectIlluminationList } from '@/api/quality/illuminationTestRecord'

export default {
  data () {
    return {
      illuminationDialogVisible: false,
      illuminationFormTitle: '',
      illuminationForm: {
        id: '',
        roomName: '',
        tubeQuantity: '',
        onePoints: '',
        twoPoints: '',
        threePoints: '',
        testResults: '',
        remarks: '',
        inspector: '',
        testDate: '',
        reviewer: '',
        reviewTime: ''
      },
      illuminationFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      illuminationPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceNo: ''
      }
    }
  },
  created () {
    selectIlluminationList(this.searchForm).then(res => {
      this.illuminationPage = res
    })
  },
  methods: {
    illuminationDialogClose () {
      this.$refs.illuminationFormRef.resetFields()
      this.illuminationForm.id = ''
    },
    illuminationFormSubmit () {
      if (this.illuminationFormTitle === '照度检测记录') {
        this.illuminationDialogVisible = false
        return
      }
      this.$refs.illuminationFormRef.validate(async valid => {
        if (valid) {
          if (this.illuminationFormTitle === '新增照度检测记录') {
            await addIllumination(this.illuminationForm)
          } else if (this.illuminationFormTitle === '修改照度检测记录') {
            await updateIllumination(this.illuminationForm)
          }
          this.illuminationPage = await selectIlluminationList(this.searchForm)
          this.illuminationDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.illuminationFormTitle = '新增照度检测记录'
      this.illuminationDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteIllumination(row.id)
        if (this.illuminationPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.illuminationPage = await selectIlluminationList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.illuminationFormTitle = '修改照度检测记录'
      this.illuminationDialogVisible = true
      this.selectIlluminationById(row.id)
    },
    handleInfo (index, row) {
      this.illuminationFormTitle = '照度检测记录详情'
      this.illuminationDialogVisible = true
      this.selectIlluminationById(row.id)
    },
    selectIlluminationById (id) {
      selectIlluminationInfo(id).then(res => {
        this.illuminationForm.id = res.id
        this.illuminationForm.roomName = res.roomName
        this.illuminationForm.tubeQuantity = res.tubeQuantity
        this.illuminationForm.onePoints = res.onePoints
        this.illuminationForm.twoPoints = res.twoPoints
        this.illuminationForm.threePoints = res.threePoints
        this.illuminationForm.testResults = res.testResults
        this.illuminationForm.remarks = res.remarks
        this.illuminationForm.inspector = res.inspector
        this.illuminationForm.testDate = res.testDate
        this.illuminationForm.reviewer = res.reviewer
        this.illuminationForm.reviewTime = res.reviewTime
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectIlluminationList(this.searchForm).then(res => {
        this.illuminationPage= res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectIlluminationList(this.searchForm).then(res => {
        this.illuminationPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectIlluminationList(this.searchForm).then(res => {
        this.illuminationPage = res
      })
    }
  }
}
</script>

<style>
</style>
